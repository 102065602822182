import { graphql } from '~publish/graphql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { useChannels, GetSidebarInfo } from './useChannels'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { toast } from '@buffer-mono/popcorn'

/**
 * GraphQL mutation for reordering channels within an account
 */
const OrderChannelForAccountMutation = graphql(`
  mutation OrderChannelForAccount($input: OrderChannelForAccountInput!) {
    orderChannelForAccount(input: $input) {
      ... on EmptySuccess {
        __typename
      }
      ... on MutationError {
        __typename
        message
      }
    }
  }
`)

/**
 * Custom hook for managing channel reordering mutations.
 * This hook provides a function to reorder channels within the current organization.
 * It handles optimistic updates and error handling for the reordering operation.
 *
 * @returns {Function} A function that takes drag and hover indices to reorder channels
 * @example
 * ```tsx
 * const orderChannel = useOrderChannelMutation();
 * // Later in drag-and-drop handler:
 * await orderChannel(dragIndex, hoverIndex);
 * ```
 */
export function useOrderChannelMutation(): (
  dragIndex: number,
  hoverIndex: number,
) => Promise<void> {
  const { channels } = useChannels()
  const currentOrganizationId = useCurrentOrganization()?.id
  const [orderChannel] = useTypedMutation(
    OrderChannelForAccountMutation,
    (data) => data.orderChannelForAccount,
    {
      successTypename: 'EmptySuccess',
      refetchQueries: [GetSidebarInfo],
    },
  )

  /**
   * Reorders a channel by moving it from one position to another.
   * Handles optimistic updates in the Apollo cache and error handling.
   *
   * @param {number} dragIndex - The current index of the channel being moved
   * @param {number} hoverIndex - The target index where the channel should be moved to
   * @returns {Promise<void>}
   * @throws {Error} When the channel reordering operation fails
   */
  return async (dragIndex: number, hoverIndex: number): Promise<void> => {
    if (!channels || channels.length === 0 || !currentOrganizationId) return

    // Get the channel being moved
    const draggedChannel = channels[dragIndex]

    // Create a new array with the updated order for optimistic update
    const newChannels = [...channels]
    newChannels.splice(dragIndex, 1)
    newChannels.splice(hoverIndex, 0, draggedChannel)

    // When moving a channel down, we want to position it after the channel that was before our target position
    // When moving a channel up, we want to position it after the channel that will be before it in its new position
    const isMovingDown = dragIndex < hoverIndex
    const targetIndex = isMovingDown ? hoverIndex : hoverIndex - 1
    const afterChannel = targetIndex >= 0 ? channels[targetIndex] : null

    if (!draggedChannel?.id) return

    try {
      const { success, error } = await orderChannel({
        variables: {
          input: {
            organizationId: currentOrganizationId,
            channelId: draggedChannel.id,
            afterChannelId: afterChannel?.id,
          },
        },
        optimisticResponse: {
          orderChannelForAccount: {
            __typename: 'EmptySuccess',
          },
        },
        update: (cache) => {
          const existingData = cache.readQuery({
            query: GetSidebarInfo,
          })

          if (!existingData?.account?.currentOrganization) return

          cache.writeQuery({
            query: GetSidebarInfo,
            data: {
              account: {
                ...existingData.account,
                currentOrganization: {
                  ...existingData.account.currentOrganization,
                  channels: newChannels,
                },
              },
            },
          })
        },
      })

      if (!success) {
        toast.error(`Failed to reorder channels: ${error.message}`)
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Failed to reorder channels: ${error.message}`)
      } else {
        toast.error('Failed to reorder channels')
      }
    }
  }
}
