import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import {
  Button,
  CompassIcon,
  DropdownMenu,
  Flex,
  Heading,
  MessageCircleHeartIcon,
  PlusIcon,
  Sidebar,
  FolderIcon,
  Skeleton,
  toast,
  useIsMobileScreenSize,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact, Client, Product } from '@buffer-mono/tracking-plan'
import { useHistory, useParams } from 'react-router-dom'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { PageLayout } from '~publish/components/PageLayout'
import { graphql, readFragment } from '~publish/graphql'
import { range } from '~publish/helpers/range'
import { isOfType } from '~publish/helpers/typeGuards'
import { isFreeUser } from '~publish/helpers/user'
import { useCommonTrackingProperties } from '~publish/hooks/useCommonTrackingProperties'
import { usePageTitle } from '~publish/hooks/usePageTitle'
import {
  useAccount,
  useHasPaymentDetails,
  useIsOneBufferOrg,
  useOrganizationId,
} from '~publish/legacy/accountContext'
import { createPage, feedCollectionPage } from '~publish/legacy/routes'
import { navigateToMigrationHub } from '~publish/legacy/upgrade-paths/helpersUpgradePaths'
import { isNotUndefined } from '~publish/legacy/utils/isNotUndefined/isNotUndefined'
import { Limits, type FeedCTA } from '../../constants/constants'
import {
  parseErrorMessage,
  useCreateFeed,
  type WrappedCreateFeedResponse,
} from '../../hooks/useCreateFeed'
import { useDeleteFeed } from '../../hooks/useDeleteFeed'
import { useFeedItems } from '../../hooks/useFeedItems'
import { useFeedsForSidebar } from '../../hooks/useFeedsForSidebar'
import { ExploreFeedsDialog } from '../ExploreFeedsDialog/ExploreFeedsDialog'
import {
  FeedArticleCardSkeleton,
  FeedArticleLoadFailure,
} from '../FeedArticleList/FeedArticleCard'
import { FeedArticleList } from '../FeedArticleList/FeedArticleList'
import { ImportFeedsDialogDropdownMenuItem } from '../ImportFeedsDialog/ImportFeedsDialogDropdownMenuItem'
import { SubscribeToFeedDialogDropdownMenuItem } from '../SubscribeToFeedDialog/SubscribeToFeedDialogDropdownMenuItem'
import styles from './CollectionList.module.css'
import { CollectionListEmptyState } from './CollectionListEmptyState'
import { ExploreFeedsDialogDropdownMenuItem } from '../ExploreFeedsDialog/ExploreFeedsDialogDropdownMenuItem'

export const CollectionList_Collection = graphql(/* GraphQL */ `
  fragment CollectionList_Collection on FeedCollection {
    id
    name
    feeds {
      id
      name
      connectionMetadata {
        ... on RSSFeedConnectionMetadata {
          __typename
          url
        }
        ... on NewsSearchConnectionMetadata {
          __typename
          query
        }
      }
    }
  }
`)
export const GetCollectionListDetails = graphql(
  /* GraphQL */ `
    query GetCollectionListDetails($feedCollectionId: FeedCollectionId!) {
      feedCollection(input: { id: $feedCollectionId }) {
        ...CollectionList_Collection
      }
    }
  `,
  [CollectionList_Collection],
)

export const CollectionList = (): JSX.Element => {
  const { feedCollectionId } = useParams<{ feedCollectionId: string }>()
  const organizationId = useOrganizationId()
  const history = useHistory()
  const isMobileScreenSize = useIsMobileScreenSize()

  const {
    data,
    loading: loadingCollectionData,
    error: collectionListError,
  } = useQuery(GetCollectionListDetails, {
    variables: {
      feedCollectionId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const collection = readFragment(
    CollectionList_Collection,
    data?.feedCollection,
  )

  const {
    loading: loadingFeedItems,
    items,
    fetchingMore,
    refetch,
    lastElementRef,
    error: feedItemsError,
  } = useFeedItems({
    organizationId,
    feedCollectionId,
  })

  // We need this to make sure the Empty state stays rendered while feeds are being
  // added inside of its dialogs, otherwise as soon as one feed is added the empty state
  // and dialog will stop being rendered
  const [isAddingFeeds, setIsAddingFeeds] = React.useState(false)
  const shouldRenderEmptyState =
    (!loadingCollectionData && !collection?.feeds?.length) || isAddingFeeds
  const handleEmptyStateDialogOpenChange = (open: boolean): void => {
    setIsAddingFeeds(open)
  }

  usePageTitle(collection?.name ? `${collection.name} (Feed)` : 'Feeds')

  const feeds = React.useMemo(
    () =>
      collection?.feeds.map((feed) => ({
        id: feed.id,
        name: feed.name,
        url: isOfType(feed.connectionMetadata, 'RSSFeedConnectionMetadata')
          ? feed.connectionMetadata?.url
          : undefined,
      })) ?? [],
    [collection?.feeds],
  )
  const existingFeeds = feeds
    .map((feed) => (feed.url ? { id: feed.id, url: feed.url } : undefined))
    .filter(isNotUndefined)

  /**
   * Calculate limits
   */
  const { account } = useAccount()
  const tier = isFreeUser(account) ? 'free' : 'paid'

  // Get the total number of feeds for the organization with cache-first to calculate remaining feeds
  const { totalFeeds } = useFeedsForSidebar({
    organizationId,
    fetchPolicy: 'cache-first',
  })
  const totalRemainingFeeds = Limits.feeds[tier].total - totalFeeds
  const collectionRemainingFeeds =
    Limits.feeds[tier].perCollection - feeds.length
  // If the total remaining feeds is less than the collection remaining feeds, use the total remaining feeds
  const remainingFeedsCount =
    collectionRemainingFeeds < totalRemainingFeeds
      ? collectionRemainingFeeds
      : totalRemainingFeeds
  const limitReached =
    feeds.length >= Limits.feeds[tier].perCollection ||
    totalFeeds >= Limits.feeds[tier].total

  /**
   * Event handlers
   */
  const isOneBufferOrg = useIsOneBufferOrg()
  const hasPaymentDetails = useHasPaymentDetails()
  const commonTrackingProperties = useCommonTrackingProperties()
  const baseTrackingProperties = React.useMemo(
    () => ({
      organizationId,
      clientName: Product.Publish,
      product: Client.PublishWeb,
      hasPaymentDetails,
      isFreeUser: tier === 'free',
      ...commonTrackingProperties,
    }),
    [commonTrackingProperties, hasPaymentDetails, tier, organizationId],
  )

  const handleUpgradeClick = ({
    cta,
    upgradePathName,
  }: {
    cta: CTA
    upgradePathName: string
  }): void => {
    const trackingProperties = {
      ...baseTrackingProperties,
      cta,
      upgradePathName,
    }
    if (isOneBufferOrg) {
      BufferTrackerReact.cTAClicked(trackingProperties)

      const { MODALS, actions } = window?.appshell || {}
      actions.openModal(MODALS.planSelector, trackingProperties)
    } else {
      navigateToMigrationHub(cta)
    }
  }

  const [createFeed] = useCreateFeed()
  const handleCreateFeed = React.useCallback(
    async ({
      feedUrl,
      cta,
    }: {
      feedUrl: string
      cta: FeedCTA
    }): Promise<WrappedCreateFeedResponse> => {
      if (!organizationId) {
        throw new Error('Organization ID is required')
      }

      const result = await createFeed({
        variables: {
          input: {
            organizationId,
            collectionId: collection?.id,
            rssAtom: { url: feedUrl },
          },
        },
      })

      if (result.success) {
        toast.success('Successfully added feed')
        BufferTrackerReact.feedSubscribed({
          product: 'publish',
          organizationId,
          feedId: result.data.feed.id,
          feedCollectionId: collection?.id ?? '',
          feedUrl,
          feedQuery: undefined,
          feedType: 'rssAtom',
          clientName: 'publishWeb',
          cta,
        })
        refetch()
        return { feedId: result.data.feed.id, error: null }
      }
      return { feedId: null, error: parseErrorMessage(result.error) }
    },
    [createFeed, organizationId, refetch, collection?.id],
  )

  const [deleteFeed] = useDeleteFeed()
  const handleDeleteFeed = React.useCallback(
    async (deletingFeedId: string) => {
      if (!organizationId) return

      const { error } = await deleteFeed({
        variables: { input: { organizationId, id: deletingFeedId } },
      })

      if (error) {
        toast.error(`Failed to delete feed`)
        return
      }

      // Only redirect if the deleted feed is the active one
      if (!history.location.pathname.includes(deletingFeedId)) {
        return
      }
      if (collection?.id) {
        history.push(feedCollectionPage.linkTo(collection.id))
        return
      }
      history.push(createPage.route)
    },
    [deleteFeed, collection, history, organizationId],
  )

  const loadingState = useMemo(() => {
    if (collectionListError || feedItemsError)
      return {
        state: 'error',
        error: collectionListError || feedItemsError,
      } as const
    if (shouldRenderEmptyState) return { state: 'empty' } as const
    if ((loadingFeedItems || loadingCollectionData) && !items?.length)
      return { state: 'loading' } as const
    if (items && items.length > 0) return { state: 'loaded' } as const
    return { state: 'loading' } as const
  }, [
    shouldRenderEmptyState,
    loadingFeedItems,
    loadingCollectionData,
    items,
    collectionListError,
    feedItemsError,
  ])

  return (
    <PageLayout className={styles.pageLayout}>
      <PageLayout.Header>
        <PageLayout.HeaderRow>
          <Flex align="center" gap="sm">
            {isMobileScreenSize && <Sidebar.Trigger />}
            {loadingCollectionData ? (
              <>
                <Skeleton width={200} height={36} />
                <Skeleton width={64} height={36} />
              </>
            ) : (
              <>
                <FolderIcon className={styles.titleIcon} />
                <Heading size="large" truncate="ellipsis">
                  {collection?.name}
                </Heading>
              </>
            )}
          </Flex>
          <PageLayout.Actions>
            <FeedbackWidget id="content-feeds-1" source="create">
              <Button variant="tertiary" size="large">
                <MessageCircleHeartIcon />
                Share Feedback
              </Button>
            </FeedbackWidget>
            {!isMobileScreenSize && (
              <DropdownMenu>
                <DropdownMenu.Trigger>
                  <Button size="large" variant="secondary">
                    <PlusIcon />
                    Add Feed
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  <SubscribeToFeedDialogDropdownMenuItem
                    existingFeeds={existingFeeds}
                    tier={tier}
                    limitReached={limitReached}
                    onAddFeed={handleCreateFeed}
                    onOpenChange={handleEmptyStateDialogOpenChange}
                    onUpgrade={handleUpgradeClick}
                  />
                  <ImportFeedsDialogDropdownMenuItem
                    existingFeeds={existingFeeds}
                    remainingFeedsCount={remainingFeedsCount}
                    tier={tier}
                    limitReached={limitReached}
                    onAddFeed={handleCreateFeed}
                    onRemoveFeed={handleDeleteFeed}
                    onOpenChange={handleEmptyStateDialogOpenChange}
                    onUpgrade={handleUpgradeClick}
                  />
                  <DropdownMenu.Separator />
                  <ExploreFeedsDialogDropdownMenuItem
                    existingFeeds={existingFeeds}
                    tier={tier}
                    limitReached={limitReached}
                    onAddFeed={handleCreateFeed}
                    onRemoveFeed={handleDeleteFeed}
                    onUpgrade={handleUpgradeClick}
                  />
                </DropdownMenu.Content>
              </DropdownMenu>
            )}
          </PageLayout.Actions>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container size="narrow" className={styles.feedContent}>
        {loadingState.state === 'empty' && (
          <CollectionListEmptyState>
            <ExploreFeedsDialog
              existingFeeds={existingFeeds}
              tier={tier}
              limitReached={limitReached}
              onAddFeed={handleCreateFeed}
              onRemoveFeed={handleDeleteFeed}
              onOpenChange={handleEmptyStateDialogOpenChange}
              onUpgrade={(): void =>
                handleUpgradeClick({
                  cta: 'create-feeds-upgradeDialog-upgradeButton-1',
                  upgradePathName: 'feeds-exploreFeeds-upgrade',
                })
              }
            >
              <Button variant="secondary" size="large">
                <CompassIcon /> Explore Feeds
              </Button>
            </ExploreFeedsDialog>
            <DropdownMenu>
              <DropdownMenu.Trigger>
                <Button size="large" variant="primary">
                  <PlusIcon />
                  Add Feed
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <SubscribeToFeedDialogDropdownMenuItem
                  existingFeeds={existingFeeds}
                  tier={tier}
                  buttonText="Add from URL"
                  limitReached={limitReached}
                  onAddFeed={handleCreateFeed}
                  onOpenChange={handleEmptyStateDialogOpenChange}
                  onUpgrade={handleUpgradeClick}
                />
                <ImportFeedsDialogDropdownMenuItem
                  existingFeeds={existingFeeds}
                  remainingFeedsCount={remainingFeedsCount}
                  tier={tier}
                  limitReached={limitReached}
                  onAddFeed={handleCreateFeed}
                  onRemoveFeed={handleDeleteFeed}
                  onOpenChange={handleEmptyStateDialogOpenChange}
                  onUpgrade={handleUpgradeClick}
                />
              </DropdownMenu.Content>
            </DropdownMenu>
          </CollectionListEmptyState>
        )}
        {loadingState.state === 'loading' &&
          range(20).map((index) => <FeedArticleCardSkeleton key={index} />)}
        {loadingState.state === 'loaded' && (
          <FeedArticleList
            feedCollectionId={feedCollectionId}
            items={items}
            fetchingMore={fetchingMore}
            lastElementRef={lastElementRef}
          />
        )}
        {loadingState.state === 'error' && <FeedArticleLoadFailure />}
      </PageLayout.Container>
    </PageLayout>
  )
}
