import { useCallback } from 'react'
import {
  type ApolloCache,
  type DefaultContext,
  type MutationFunctionOptions,
  type MutationHookOptions,
  type NoInfer,
  type OperationVariables,
  type TypedDocumentNode,
  useMutation,
} from '@apollo/client'

import { isNotOfType, isOfType } from '~publish/helpers/typeGuards'

export type MutationError = {
  __typename: 'MutationError'
  message: string
}

const mutationError = (message: string): MutationError => ({
  __typename: 'MutationError',
  message,
})

type MutationResult =
  | ({ __typename: string } & Record<string, any>)
  | null
  | undefined

export type MutationErrorResult<
  TResult extends MutationResult,
  TSuccessTypename extends string,
> =
  | Exclude<NonNullable<TResult>, { __typename: TSuccessTypename }>
  | MutationError

type ReturnResult<
  TResult extends MutationResult,
  TSuccessTypename extends string,
> =
  | {
      success: true
      data: Extract<NonNullable<TResult>, { __typename: TSuccessTypename }>
      error: null
    }
  | {
      success: false
      data: null
      error: MutationErrorResult<TResult, TSuccessTypename>
    }

/**
 * This hook is a wrapper around `useMutation` that provides a more
 * convenient way to handle mutation results and errors for union types
 * in GraphQL responses.
 *
 * @example
 * ```ts
 * const [movePostToDrafts, { loading, data, error }] = useAction(
 *  MovePostToDrafts,
 *  // getter function for mutation result
 *  (data) => data.movePostToDraftsV2,
 *  {
 *    // name of the success type in the GraphQL response
 *    successTypename: 'PostActionSuccess',
 *    // ...options for useMutation, same as in the official docs
 *    refetchQueries: ['GetPostList'],
 *  }
 * )
 * ```
 */
export const useTypedMutation = <
  TSuccessTypename extends string,
  TMutationResult extends MutationResult,
  TData extends Record<string, any>,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
  TVariables = OperationVariables,
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  getResult: (data: TData) => TMutationResult | null | undefined,
  options: MutationHookOptions<
    NoInfer<TData>,
    NoInfer<TVariables>,
    TContext,
    TCache
  > & { successTypename: TSuccessTypename },
): readonly [
  (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  ) => Promise<ReturnResult<TMutationResult, TSuccessTypename>>,
  ReturnResult<TMutationResult, TSuccessTypename> & { loading: boolean },
] => {
  const { successTypename } = options
  const [mutate, { loading, data, error }] = useMutation(mutation, options)

  const action = useCallback(
    async (
      options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
    ) => {
      try {
        const { data, errors } = await mutate(options)
        const result = data && getResult(data)
        const isSuccess = isOfType(result, successTypename)

        return isSuccess
          ? {
              success: true as const,
              data: result,
              error: null,
            }
          : {
              success: false as const,
              error:
                result && isNotOfType(result, successTypename)
                  ? result
                  : mutationError(errors ? errors[0].message : 'Unknown error'),
              data: null,
            }
      } catch (error) {
        return {
          success: false as const,
          error: mutationError((error as Error)?.message ?? 'Unknown error'),
          data: null,
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, successTypename],
  )

  const result = data && getResult(data)
  const isSuccess = isOfType(result, successTypename)

  const toReturn: ReturnResult<TMutationResult, TSuccessTypename> & {
    loading: boolean
  } = isSuccess
    ? {
        success: true as const,
        data: result,
        error: null,
        loading,
      }
    : {
        success: false as const,
        error:
          result && isNotOfType(result, successTypename)
            ? result
            : mutationError(error ? error.message : 'Unknown error'),
        data: null,
        loading,
      }

  return [action, toReturn]
}
