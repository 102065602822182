import {
  actionTypes as dataFetchActionTypes,
  actions as dataFetchActions,
} from '@buffer-mono/async-data-fetch'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case `singleProfile_${dataFetchActionTypes.FETCH_SUCCESS}`:
        if (action.args.message) {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: action.args.message,
            }),
          )
        }
        break

      case actionTypes.PROFILE_PAUSED:
      case actionTypes.PROFILE_UNPAUSED:
        dispatch(
          dataFetchActions.fetch({
            name: 'pauseQueue',
            args: {
              profileId: action.profileId,
              paused: action.type === actionTypes.PROFILE_PAUSED,
            },
          }),
        )
        break
      case `pauseQueue_${dataFetchActionTypes.FETCH_SUCCESS}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: action.result.message,
          }),
        )
        break
      default:
        break
    }
  }
