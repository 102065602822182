import { useCallback, useMemo } from 'react'
import { type ApolloError, useQuery } from '@apollo/client'

import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { toast, useCallbackRef } from '@buffer-mono/popcorn'

import { graphql } from '~publish/graphql'
import type { DayOfWeek, ScheduleV2 } from '~publish/gql/graphql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { useWeekStartsOn } from '~publish/hooks/useWeekStartsOn'

import { DAY_OF_WEEK } from '../utils'
import type { DayOption } from '../types'
import { ChannelAccessLevel } from '@buffer-mono/app-shell/src/gql/graphql'

/**
 * Time object representing hours and minutes
 */
export type Time = {
  /** Hours (0-24) */
  hours: number
  /** Minutes (0-59) */
  minutes: number
}

/**
 * Return type for usePostingSchedule hook
 */
export type PostingScheduleHookResult = {
  /** Array of schedule entries for each day */
  schedule: ScheduleV2[]
  /** Whether the schedule data is currently loading */
  loading: boolean
  /** Any errors that occurred during data fetching */
  error: ApolloError | undefined
  /** Whether the schedule is empty (no posting times set) */
  isEmpty: boolean
  /** Whether the user has editing privileges for the channel */
  hasEditingPrivilege: boolean
  /** Toggle a day's paused state */
  togglePause: (day: DayOfWeek) => Promise<void>
  /** Add a new posting time to one or more days */
  addPostingTime: (day: DayOption, time: Time) => Promise<void>
  /** Remove a posting time from a specific day */
  removePostingTime: (day: DayOfWeek, time: Time) => Promise<void>
  /** Update an existing posting time */
  updatePostingTime: (
    day: DayOfWeek,
    time: Time,
    newTime: Time,
  ) => Promise<void>
  /** Clear the entire schedule */
  clearSchedule: () => Promise<void>
}

// GraphQL queries and mutations
const SET_POSTING_SCHEDULE_MUTATION = graphql(/* GraphQL */ `
  mutation SetPostingScheduleForChannel(
    $input: SetPostingScheduleForChannelInput!
  ) {
    setPostingScheduleForChannel(input: $input) {
      __typename
      ... on SetPostingScheduleForChannelResponse {
        postingSchedule {
          day
          paused
          times
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export const GET_POSTING_SCHEDULE_INFO = graphql(/* GraphQL */ `
  query GetPostingScheduleInfo($channelId: ChannelId!) {
    channel(input: { id: $channelId }) {
      id
      service
      serviceId
      displayName
      type
      postingSchedule {
        day
        paused
        times
      }
      accessLevel
    }
  }
`)

/**
 * Converts a Time object to a string in HH:MM format
 * @param time - The time object to convert
 * @returns A string representation of the time in HH:MM format
 */
function stringifyTime({ hours, minutes }: Time): string {
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`
}

/**
 * Hook for managing a channel's posting schedule
 *
 * This hook provides functionality to view, add, remove, update, and toggle posting times
 * for a channel's schedule. It also handles tracking events for these actions.
 *
 * @param channelId - The ID of the channel to manage the schedule for
 * @returns An object with methods and data for managing the posting schedule
 */
export function usePostingSchedule(
  channelId: string,
): PostingScheduleHookResult {
  const weekStartsOn = useWeekStartsOn()
  const organizationId = useCurrentOrganization()?.id

  // Set up GraphQL query and mutation
  const { data, error, loading } = useQuery(GET_POSTING_SCHEDULE_INFO, {
    variables: { channelId },
    fetchPolicy: 'cache-and-network',
    skip: !organizationId,
  })

  const channel = data?.channel

  const [setPostingScheduleMutation] = useTypedMutation(
    SET_POSTING_SCHEDULE_MUTATION,
    (data) => data.setPostingScheduleForChannel,
    {
      successTypename: 'SetPostingScheduleForChannelResponse',
      optimisticResponse: (variables) => ({
        setPostingScheduleForChannel: {
          __typename: 'SetPostingScheduleForChannelResponse' as const,
          postingSchedule: variables.input.postingSchedule,
        },
      }),
      update: (cache, { data }) => {
        if (
          data?.setPostingScheduleForChannel.__typename ===
          'SetPostingScheduleForChannelResponse'
        ) {
          cache.writeQuery({
            query: GET_POSTING_SCHEDULE_INFO,
            variables: { channelId },
            data: {
              channel: {
                ...cache.readQuery({
                  query: GET_POSTING_SCHEDULE_INFO,
                  variables: { channelId },
                })?.channel,
                postingSchedule:
                  data.setPostingScheduleForChannel.postingSchedule,
              },
            },
          })
        }
      },
    },
  )

  // Check if schedule is empty
  const isEmpty = useMemo(
    () =>
      channel?.postingSchedule?.every(({ times }) => times.length === 0) ??
      true,
    [channel?.postingSchedule],
  )

  /**
   * Saves the posting schedule to the server
   * @param postingSchedule - The updated posting schedule to save
   */
  const savePostingSchedule = useCallback(
    async (postingSchedule: ScheduleV2[]): Promise<void> => {
      try {
        const { success, error } = await setPostingScheduleMutation({
          variables: {
            input: {
              channelId,
              postingSchedule: postingSchedule.map((d) => ({
                day: d.day,
                paused: d.paused,
                times: d.times,
              })),
            },
          },
        })

        if (!success) {
          toast.error(
            `Sorry! Something went wrong while updating your posting times: ${error.message}`,
          )
        } else {
          toast.success('Schedule saved successfully')
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(
            `Sorry! Something went wrong while updating your posting times: ${error.message}. Would you be up for trying again?`,
          )
        } else {
          toast.error(
            `Sorry! Something went wrong while updating your posting times. Would you be up for trying again?`,
          )
        }
      }
    },
    [channelId, setPostingScheduleMutation],
  )

  // Tracking event functions
  const trackPostingTimeRemoved = useCallbackRef(
    (day: DayOfWeek, time: string): void => {
      if (!channel) return

      BufferTracker.postingTimeRemoved({
        product: Product.Publish,
        organizationId: organizationId ?? '',
        clientName: Client.PublishWeb,
        dayRemoved: DAY_OF_WEEK[day].toLowerCase(),
        timeRemoved: time,
        channel: channel.service,
        channelType: channel.type,
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.displayName ?? undefined,
      })
    },
  )

  const trackPostingTimeUpdated = useCallbackRef(
    (day: DayOfWeek, newTime: string, oldTime: string): void => {
      if (!channel) return

      BufferTracker.postingTimeUpdated({
        product: Product.Publish,
        organizationId: organizationId ?? '',
        clientName: Client.PublishWeb,
        channel: channel.service,
        channelType: channel.type,
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.displayName ?? undefined,
        dayChanged: DAY_OF_WEEK[day].toLowerCase(),
        timeRemoved: oldTime,
        timeAdded: newTime,
      })
    },
  )

  const trackClearSchedule = useCallbackRef(() => {
    if (!channel) return

    BufferTracker.postingScheduleCleared({
      product: Product.Publish,
      organizationId: organizationId ?? '',
      clientName: Client.PublishWeb,
      channel: channel.service,
      channelType: channel.type,
      channelId: channel.id,
      channelServiceId: channel.serviceId,
      channelUsername: channel.displayName ?? undefined,
    })
  })

  const trackPostingTimeAdded = useCallbackRef(
    (day: DayOption, time: string): void => {
      if (!channel) return

      BufferTracker.postingTimeAdded({
        product: Product.Publish,
        organizationId: organizationId ?? '',
        clientName: Client.PublishWeb,
        daysAdded:
          day in DAY_OF_WEEK
            ? DAY_OF_WEEK[day as DayOfWeek].toLowerCase()
            : day,
        timeAdded: time,
        channel: channel.service,
        channelType: channel.type,
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.displayName ?? undefined,
      })
    },
  )

  const trackPostingDayToggled = useCallbackRef(
    (day: DayOfWeek, paused: boolean): void => {
      if (!channel) return

      BufferTracker.postingDayToggled({
        product: Product.Publish,
        organizationId: organizationId ?? '',
        clientName: Client.PublishWeb,
        dayToggled: DAY_OF_WEEK[day].toLowerCase(),
        state: !paused ? 'enabled' : 'disabled',
        channel: channel.service,
        channelType: channel.type,
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.displayName ?? undefined,
      })
    },
  )

  /**
   * Toggles a day's paused state in the schedule
   * @param day - The day to toggle
   */
  const togglePause = useCallback(
    async (day: DayOfWeek): Promise<void> => {
      if (!channel?.postingSchedule) {
        toast.error('No posting schedule available')
        return
      }

      const newPostingSchedule = [...channel.postingSchedule]
      const dayIndex = newPostingSchedule.findIndex((d) => d.day === day)

      if (dayIndex !== -1) {
        newPostingSchedule[dayIndex] = {
          ...newPostingSchedule[dayIndex],
          paused: !newPostingSchedule[dayIndex].paused,
        }

        await savePostingSchedule(newPostingSchedule)
        trackPostingDayToggled(day, newPostingSchedule[dayIndex].paused)
      }
    },
    [channel?.postingSchedule, savePostingSchedule, trackPostingDayToggled],
  )

  /**
   * Adds a posting time to one or more days in the schedule
   * @param day - The day(s) to add the time to (can be a specific day or a group like 'every day')
   * @param time - The time to add
   */
  const addPostingTime = useCallback(
    async (day: DayOption, time: Time): Promise<void> => {
      if (!channel?.postingSchedule) {
        toast.error('No posting schedule available')
        return
      }

      const newPostingSchedule = [...channel.postingSchedule]
      const timeString = stringifyTime(time)

      // Map day option to actual days of the week
      const daysToUpdate: DayOfWeek[] = (() => {
        switch (day) {
          case 'every day':
            return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
          case 'weekdays':
            return ['mon', 'tue', 'wed', 'thu', 'fri']
          case 'weekends':
            return ['sat', 'sun']
          default:
            return [day]
        }
      })()

      // Update all relevant days
      daysToUpdate.forEach((dayOfWeek) => {
        const dayIndex = newPostingSchedule.findIndex(
          (d) => d.day === dayOfWeek,
        )
        if (dayIndex !== -1) {
          const isAlreadyInSchedule =
            newPostingSchedule[dayIndex].times.includes(timeString)
          if (!isAlreadyInSchedule) {
            newPostingSchedule[dayIndex] = {
              ...newPostingSchedule[dayIndex],
              times: [...newPostingSchedule[dayIndex].times, timeString].sort(),
            }
          }
        }
      })

      await savePostingSchedule(newPostingSchedule)
      trackPostingTimeAdded(day, timeString)
    },
    [channel?.postingSchedule, savePostingSchedule, trackPostingTimeAdded],
  )

  /**
   * Removes a posting time from a specific day in the schedule
   * @param day - The day to remove the time from
   * @param time - The time to remove
   */
  const removePostingTime = useCallback(
    async (day: DayOfWeek, time: Time): Promise<void> => {
      if (!channel?.postingSchedule) {
        toast.error('No posting schedule available')
        return
      }

      const newPostingSchedule = [...channel.postingSchedule]
      const dayIndex = newPostingSchedule.findIndex((d) => d.day === day)
      const timeString = stringifyTime(time)

      if (dayIndex !== -1) {
        const timeIndex = newPostingSchedule[dayIndex].times.findIndex(
          (t) => t === timeString,
        )
        if (timeIndex !== -1) {
          const times = [...newPostingSchedule[dayIndex].times]
          times.splice(timeIndex, 1)
          newPostingSchedule[dayIndex] = {
            ...newPostingSchedule[dayIndex],
            times,
          }
          await savePostingSchedule(newPostingSchedule)
          trackPostingTimeRemoved(day, timeString)
        }
      }
    },
    [channel?.postingSchedule, savePostingSchedule, trackPostingTimeRemoved],
  )

  /**
   * Updates an existing posting time in the schedule
   * @param day - The day containing the time to update
   * @param time - The current time to be updated
   * @param newTime - The new time value
   */
  const updatePostingTime = useCallback(
    async (day: DayOfWeek, time: Time, newTime: Time): Promise<void> => {
      if (!channel?.postingSchedule) {
        toast.error('No posting schedule available')
        return
      }

      const newPostingSchedule = [...channel.postingSchedule]
      const dayIndex = newPostingSchedule.findIndex((d) => d.day === day)
      const oldTimeString = stringifyTime(time)
      const newTimeString = stringifyTime(newTime)

      if (dayIndex !== -1) {
        const timeIndex = newPostingSchedule[dayIndex].times.findIndex(
          (t) => t === oldTimeString,
        )
        if (timeIndex !== -1) {
          const times = [...newPostingSchedule[dayIndex].times]
          times[timeIndex] = newTimeString
          newPostingSchedule[dayIndex] = {
            ...newPostingSchedule[dayIndex],
            times: times.sort(),
          }
          await savePostingSchedule(newPostingSchedule)
          trackPostingTimeUpdated(day, newTimeString, oldTimeString)
        }
      }
    },
    [channel?.postingSchedule, savePostingSchedule, trackPostingTimeUpdated],
  )

  /**
   * Clears the entire posting schedule
   */
  const clearSchedule = useCallback(async (): Promise<void> => {
    await savePostingSchedule([])
    trackClearSchedule()
  }, [savePostingSchedule, trackClearSchedule])

  /**
   * Adjusts the schedule order based on week start preference
   */
  const adjustedSchedule = useMemo(() => {
    if (!channel?.postingSchedule) {
      return []
    }

    if (weekStartsOn === 'sunday') {
      // move sunday to the start of the week
      const sundayIndex = channel.postingSchedule.findIndex(
        (day) => day.day === 'sun',
      )
      if (sundayIndex !== -1) {
        const sunday = channel.postingSchedule[sundayIndex]
        const newSchedule = [...channel.postingSchedule]
        // Insert Sunday at the beginning and remove it from its original position
        newSchedule.splice(0, 0, sunday)
        newSchedule.splice(sundayIndex + 1, 1)
        return newSchedule
      }
    }
    return channel.postingSchedule
  }, [weekStartsOn, channel?.postingSchedule])

  const hasEditingPrivilege = useMemo(() => {
    return channel?.accessLevel === ChannelAccessLevel.FullPosting
  }, [channel?.accessLevel])

  return {
    schedule: adjustedSchedule,
    loading,
    error,
    isEmpty,
    hasEditingPrivilege,
    togglePause,
    addPostingTime,
    removePostingTime,
    updatePostingTime,
    clearSchedule,
  }
}
