import React, { useEffect, useState } from 'react'

import {
  AlertDialog,
  Button,
  Flex,
  Heading,
  Paragraph,
  Strong,
  toast,
} from '@buffer-mono/popcorn'

import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'
import { useIsAdmin } from '~publish/legacy/accountContext'
import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'

import commonStyles from '../../PostingSchedule.module.css'

export const UpdateTimezoneForChannelMutationDocument = graphql(/* GraphQL */ `
  mutation UpdateTimezoneForChannel($input: UpdateTimezoneForChannelInput!) {
    updateTimezoneForChannel(input: $input) {
      __typename
      ... on UpdateTimezoneForChannelResponse {
        channel {
          id
          timezone
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

const TimezoneInputForm = (): JSX.Element => {
  const isAdmin = useIsAdmin()
  const channel = useSelectedChannel()
  const channelId = channel.id
  const channelTimezone = channel.timezone

  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    channelTimezone || '',
  )
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  useEffect(() => {
    if (channelTimezone) {
      setSelectedTimezone(channelTimezone)
    }
  }, [channelTimezone])

  const [updateChannelTimezone, { loading }] = useTypedMutation(
    UpdateTimezoneForChannelMutationDocument,
    (data) => data.updateTimezoneForChannel,
    {
      successTypename: 'UpdateTimezoneForChannelResponse',
    },
  )

  const handleTimezoneChange = (timezone: string): void => {
    if (timezone !== channelTimezone) {
      setSelectedTimezone(timezone)
      setIsConfirmationOpen(true)
    }
  }

  const handleTimezoneConfirm = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault()

    if (!selectedTimezone || !channelId) return

    try {
      const { success, error } = await updateChannelTimezone({
        variables: {
          input: {
            channelId,
            timezone: selectedTimezone,
          },
        },
      })

      if (success) {
        toast.success('Channel time zone updated and all posts are rescheduled')
      } else {
        toast.error(
          `Sorry! Something went wrong while updating your time zone: ${error.message}`,
        )
      }
    } catch (error) {
      toast.error(
        `Sorry! Something went wrong while updating your time zone: ${
          error instanceof Error ? error.message : 'Unknown error'
        }. Would you be up for trying again?`,
      )
    } finally {
      setIsConfirmationOpen(false)
    }
  }

  return (
    <Flex
      justify="between"
      align="center"
      style={{ width: '100%' }}
      data-testid="timezone-form-wrapper"
    >
      <Flex direction="column">
        <Heading size="small" as="h3">
          Time Zone
        </Heading>
        <Paragraph className={commonStyles.postingScheduleParagraph}>
          Time zone used for scheduling queue for this channel
        </Paragraph>
      </Flex>
      <Flex direction="row" align="center" gap="xs">
        <TimeZoneSelect
          value={selectedTimezone}
          disabled={!isAdmin}
          size="large"
          variant="secondary"
          onChange={handleTimezoneChange}
        />
        {/* Using AlertDialog without trigger is not ideal, but we're 
        considering to sunset channel-specific time zone in general, 
        so did not invest time here to change its behavior  */}
        <AlertDialog
          open={isConfirmationOpen}
          onOpenChange={(open): void => {
            if (!open) {
              setIsConfirmationOpen(open)
              setSelectedTimezone(channelTimezone || '')
            }
          }}
        >
          <AlertDialog.Content>
            <AlertDialog.Title>
              Change channel time zone and reschedule posts?
            </AlertDialog.Title>
            <AlertDialog.Description>
              Changing the channel time zone to{' '}
              <Strong>{selectedTimezone}</Strong> will reschedule posts in
              channel&quot;s queue to match the new time zone.
            </AlertDialog.Description>
            <AlertDialog.Actions>
              <AlertDialog.Cancel>
                <Button variant="secondary" size="large">
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="primary"
                  size="large"
                  loading={loading}
                  onClick={handleTimezoneConfirm}
                >
                  Change Time Zone
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Actions>
          </AlertDialog.Content>
        </AlertDialog>
      </Flex>
    </Flex>
  )
}

export { TimezoneInputForm }
