import {
  CriticalIcon,
  EmptyState,
  Heading,
  IconButton,
  Input,
  SearchIcon,
  Sidebar,
  Text,
  useSidebar,
  CloseIcon,
  useMediaQuery,
  useDebounceCallback,
} from '@buffer-mono/popcorn'
import React, { useEffect, useState } from 'react'

import { ManagementNav } from './ManagementNav'
import { useChannels } from './useChannels'
import { ChannelNav, ChannelNavSkeleton } from './ChannelNav'
import { useOrderChannelMutation } from './useOrderChannelMutation'

import styles from './PublishSidebar.module.css'

const SEARCH_CHANNELS_THRESHOLD = 5

export function PublishSidebar(): JSX.Element {
  const { channels, loading, error } = useChannels()
  const isSmallScreen = useMediaQuery('(width < 920px)')
  const orderChannel = useOrderChannelMutation()

  const { open, setOpen } = useSidebar()
  const [hovered, setHovered] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false)
    }
  }, [isSmallScreen, setOpen])

  const debouncedSetHovered = useDebounceCallback(
    (value: boolean) => setHovered(value),
    200,
  )

  const handleMouseEnter = (): void => {
    if (open) {
      return
    }

    debouncedSetHovered(true)
  }

  const handleMouseLeave = (): void => {
    if (open) {
      return
    }

    debouncedSetHovered.cancel() // Cancel any pending hover
    setHovered(false)
    cancelSearch()
  }

  const handleToggleSidebar = (): void => {
    if (open) {
      cancelSearch()
    }
    setHovered(false)
  }

  const cancelSearch = (): void => {
    setSearchQuery('')
    setSearching(false)
  }

  const startSearch = (): void => {
    setSearching(true)
  }

  return (
    <Sidebar
      collapsible="icon"
      data-hovered={!open && hovered ? 'true' : 'false'}
      className={styles.sidebar}
      onMouseLeave={handleMouseLeave}
    >
      <Sidebar.Header className={styles.header}>
        <Heading
          as="h3"
          size="small"
          id="channels-heading"
          className={styles.heading}
        >
          Channels
        </Heading>
        {channels.length > SEARCH_CHANNELS_THRESHOLD ? (
          <IconButton
            data-search-button
            aria-pressed={searching}
            label="Search channels"
            variant="tertiary"
            tooltip="Search channels"
            className={styles.searchButton}
            onClick={searching ? cancelSearch : startSearch}
          >
            <SearchIcon />
          </IconButton>
        ) : null}
        <Sidebar.Trigger
          className={styles.toggleButton}
          onClick={handleToggleSidebar}
        />
        {searching ? (
          <ChannelSearchInput
            value={searchQuery}
            onValueChange={setSearchQuery}
            onCancel={cancelSearch}
          />
        ) : null}
      </Sidebar.Header>
      <Sidebar.Content onMouseEnter={handleMouseEnter}>
        <Sidebar.Group aria-labelledby="channels-heading">
          {error && <ErrorState error={error} />}
          {loading && <ChannelNavSkeleton />}
          {channels && !error && !loading && (
            <ChannelNav
              channels={channels}
              searching={searching}
              searchQuery={searchQuery}
              onReorder={orderChannel}
            />
          )}
        </Sidebar.Group>
      </Sidebar.Content>
      {!searching && (
        <Sidebar.Footer onMouseEnter={handleMouseEnter}>
          <ManagementNav />
        </Sidebar.Footer>
      )}
    </Sidebar>
  )
}

type ChannelSearchInputProps = React.ComponentProps<'input'> & {
  value: string
  onValueChange: (value: string) => void
  onCancel: () => void
}

function ChannelSearchInput({
  value,
  onValueChange,
  onCancel,
}: ChannelSearchInputProps): JSX.Element {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onValueChange(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      onCancel()
    }
  }

  const handleCancel = (): void => {
    onCancel()
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    // HACK: This is a workaround to prevent the search input from losing focus
    // when the user clicks on the search toggle button.
    if (e.relatedTarget?.hasAttribute('data-search-button')) {
      return
    }

    if (!value) {
      onCancel()
    }
  }

  return (
    <Input
      // eslint-disable-next-line jsx-a11y/no-autofocus -- This is a search input
      autoFocus
      size="large"
      value={value}
      className={styles.searchInput}
      placeholder="Search channels"
      prefix={<SearchIcon />}
      suffix={
        <IconButton
          label="Cancel"
          variant="tertiary"
          size="small"
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      }
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

function ErrorState({ error }: { error: Error }): JSX.Element {
  return (
    <EmptyState variant="critical" size="small">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Failed to load channels</EmptyState.Heading>
      <EmptyState.Description>
        Try refreshing the page, or contact support if the issue persists.{' '}
        {error.message && <Text color="critical">{error.message}</Text>}
      </EmptyState.Description>
    </EmptyState>
  )
}
