import { AlertDialog, Button } from '@buffer-mono/popcorn'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmCloseModal = ({
  onCancel,
  onConfirmClose,
  open,
  onOpenChange,
}: {
  onCancel: (event: React.MouseEvent) => void
  onConfirmClose: () => void
  open: boolean
  onOpenChange?: (open: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Title>
          {t('content.modals.confirmClose.title')}
        </AlertDialog.Title>
        <AlertDialog.Description>
          {t('content.modals.confirmClose.description')}
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary" onClick={onCancel}>
              {t('content.modals.confirmClose.cancel')}
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="critical" onClick={onConfirmClose}>
              {t('content.modals.confirmClose.confirm')}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

export default ConfirmCloseModal
