import partition from 'lodash/partition'
import {
  ButtonsQueuingTypesMap,
  ErrorTypes,
  FloatingErrorCodes,
  InlineSaveButtonTypes,
  NotificationScopes,
  QueueingTypes,
  UpgradeErrorCodes,
} from '../AppConstants'
import { saveYoutubeDefaultsToStorage } from '../components/youtube/utils'
import AppDispatcher from '../dispatcher'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import { ActionTypes } from '../state/ActionTypes'
import AppStore from '../stores/AppStore'
import ComposerStore from '../stores/ComposerStore'
import { extractSavedUpdatesIdsFromResponses } from '../utils/APIDataTransforms'
import AppHooks from '../utils/LifecycleHooks'
import { removeLinkFromErrorMessageText } from '../utils/StringUtils'
import WebAPIUtils from '../utils/WebAPIUtils'
import ComposerActionCreators from './ComposerActionCreators'
import NotificationActionCreators from './NotificationActionCreators'

const getDefaultQueueingType = () => {
  const { saveButtons } = AppStore.getOptions()
  const [inlineSaveButtonTypes, stackedSaveButtonTypes] = partition(
    saveButtons,
    (button) => InlineSaveButtonTypes.includes(button),
  )

  let saveButtonType

  if (inlineSaveButtonTypes.length > 0) {
    saveButtonType = inlineSaveButtonTypes.pop()
  } else if (stackedSaveButtonTypes.length > 0) {
    saveButtonType = stackedSaveButtonTypes.shift()
  }

  // @ts-expect-error TS(2345) FIXME: Argument of type '"ADD_TO_QUEUE" | "SHARE_NEXT" | ... Remove this comment to see the full error message
  return ButtonsQueuingTypesMap.get(saveButtonType)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'drafts' implicitly has an 'any' type.
const formatDrafts = (drafts, { ideaId, hasCampaignsToTagsFeature }) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
  return drafts.map((draft) => {
    const data = {
      ...draft,
    }
    if (ideaId) {
      // add ideaId to draft
      data.ideaId = ideaId
    }

    if (hasCampaignsToTagsFeature) {
      delete data.campaignId
    } else {
      delete data.tags
    }
    return data
  })
}

const AppActionCreators = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  triggerInteraction: (message) => {
    AppHooks.handleActionTaken(message)
  },

  saveDrafts: (
    queueingType = getDefaultQueueingType(),
    {
      customScheduleTime = null,
      shouldSkipEmptyTextAlert = true,
      saveDraftsFromQueue = false,
      hasCampaignsToTagsFeature = false,
    } = {},
  ) => {
    const { isSavingPossible, isSavingDraftPossible } = AppStore.getAppState()
    const { shouldAlwaysSkipEmptyTextAlert } = AppStore.getUserData()
    const metaData = AppStore.getMetaData()
    const { ideaId } = metaData
    const hadTextToPrefill =
      metaData.text ||
      metaData.url ||
      metaData.via ||
      (metaData.retweetData && metaData.retweetData.comment)

    if (!isSavingPossible && !isSavingDraftPossible)
      return Promise.reject(new Error('Saving is not possible'))

    const { saveOptions } = AppStore.getOptions()
    // @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '{ readonly ... Remove this comment to see the full error message
    const allowedQueuingTypes = saveOptions.map((saveButtonType) =>
      ButtonsQueuingTypesMap.get(saveButtonType),
    )
    if (!allowedQueuingTypes.includes(queueingType))
      return Promise.reject(new Error('Invalid post action'))

    const shouldShowEmptyTextAlert =
      // @ts-expect-error TS(2339) FIXME: Property 'isPrefillingExistingUpdate' does not exi... Remove this comment to see the full error message
      !metaData.isPrefillingExistingUpdate &&
      hadTextToPrefill &&
      !shouldAlwaysSkipEmptyTextAlert &&
      !shouldSkipEmptyTextAlert &&
      AppStore.hasFBDraftWithNoText()

    if (shouldShowEmptyTextAlert) {
      ModalActionCreators.openModal('EmptyTextAlert', { queueingType })
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject()
    }

    const enabledDrafts = ComposerStore.getEnabledDrafts().filter(
      (draft) => !ComposerStore.isDraftLocked(draft.id),
    )

    /**
     * Threaded Updates: we need to switch active editor to the first update in the Thread
     * in order to properly preserve it. ComposerActionCreators.switchActiveThreadEditor()
     * updates underlying threads structure that we rely on later to publish Thread.
     */
    const threadedDrafts = enabledDrafts.filter((draft) => draft.hasThread())
    const expandedComposerId = AppStore.getExpandedComposerId()
    if (
      threadedDrafts &&
      threadedDrafts.length > 0 &&
      expandedComposerId &&
      threadedDrafts.some(
        (draft) => draft.id === AppStore.getExpandedComposerId(),
      )
    ) {
      ComposerActionCreators.switchActiveThreadEditor(expandedComposerId, 0)
    }

    const formattedDrafts = formatDrafts(enabledDrafts, {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ saveDraftsFromQueue: boolean; ... Remove this comment to see the full error message
      saveDraftsFromQueue,
      ideaId,
      hasCampaignsToTagsFeature,
    })

    saveYoutubeDefaultsToStorage(formattedDrafts)

    const data = {
      queueingType,
      customScheduleTime,
      profiles: AppStore.getSelectedProfiles(),
      drafts: formattedDrafts,
    }

    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SAVE_DRAFTS,
      data: { queueingType },
    })

    return WebAPIUtils.saveDrafts(data)
      .then(async ({ successfulResponses, unsuccessfulResponses }) => {
        // If we're "saving and approving", approve successfully saved updates
        if (
          queueingType === QueueingTypes.SAVE_AND_APPROVE &&
          successfulResponses.length > 0
        ) {
          const ids = extractSavedUpdatesIdsFromResponses(successfulResponses)
          await WebAPIUtils.approveDrafts(ids)
        }

        return { successfulResponses, unsuccessfulResponses }
      })
      .then(({ successfulResponses, unsuccessfulResponses }) => {
        AppDispatcher.handleApiAction({
          actionType: ActionTypes.COMPOSER_RECEIVE_SAVED_DRAFTS,
        })

        // Queue aggregate notification to let user know of general state of saved updates
        const areAllResponsesSuccessful = unsuccessfulResponses.length === 0

        let entityName = 'post'
        if (
          successfulResponses.length === 1 &&
          data.drafts.length &&
          data.drafts[0].updateType === 'thread'
        ) {
          entityName = 'thread'
        }

        if (areAllResponsesSuccessful) {
          const updates = successfulResponses
            .map((response) => {
              if ('updates' in response) {
                return response.updates
              }
              return []
            })
            .flat(1)
          const firstUpdate = updates[0]

          const saveSingularCopy = metaData.draftMode
            ? 'Great! Your draft is saved.'
            : `Great! The ${entityName} has been saved.`
          const savePluralCopy = metaData.draftMode
            ? 'Great! Your drafts are saved.'
            : 'Great! All posts have been saved.'

          const shareNowSingularCopy =
            firstUpdate?.status === 'sent'
              ? `Great! The ${entityName} has been shared.`
              : `Great! The ${entityName} has been scheduled for publishing. We will let you know about the progress.`
          const shareNowPluralCopy =
            firstUpdate?.status === 'sent'
              ? 'Great! All posts have been shared.'
              : 'Great! All posts have been scheduled for publishing. We will let you know about the progress.'

          const successfulResponseMessageMap = new Map([
            [
              QueueingTypes.QUEUE,
              {
                singular: `Great! The ${entityName} has been added to your queue.`,
                plural: 'Great! All posts have been added to your queue.',
              },
            ],
            [
              QueueingTypes.NEXT,
              {
                singular: `Great! The ${entityName} has been added to your queue.`,
                plural: 'Great! All posts have been added to your queue.',
              },
            ],
            [
              QueueingTypes.NOW,
              {
                singular: shareNowSingularCopy,
                plural: shareNowPluralCopy,
              },
            ],
            [
              QueueingTypes.CUSTOM,
              {
                singular: `Great! The ${entityName} has been scheduled.`,
                plural: 'Great! All posts have been scheduled.',
              },
            ],
            [
              QueueingTypes.SAVE,
              {
                singular: saveSingularCopy,
                plural: savePluralCopy,
              },
            ],
            [
              QueueingTypes.SAVE_AND_APPROVE,
              {
                singular: `Great! The ${entityName} has been saved.`,
                plural: 'Great! All posts have been saved.',
              },
            ],
            [
              QueueingTypes.ADD_DRAFT,
              {
                singular: 'Great! Your draft is saved.',
                plural: 'Great! Your drafts are saved.',
              },
            ],
            [
              QueueingTypes.NEXT_DRAFT,
              {
                singular: 'Great! Your draft is saved.',
                plural: 'Great! Your drafts are saved.',
              },
            ],
            [
              QueueingTypes.CUSTOM_DRAFT,
              {
                singular: 'Great! Your draft is saved.',
                plural: 'Great! Your drafts are saved.',
              },
            ],
          ])

          // Currently, we only show a deep link if
          // there's a single post created.
          // Longer term, we'll want to figure out how to
          // show a deep link for multiple posts/post group.
          // We also need to determine whether its being saved
          // as a draft or a post
          let entityType = entityName
          let tab = 'queue'
          if (firstUpdate?.status === 'draft') {
            tab = firstUpdate?.needsApproval ? 'approval' : 'draft'
            entityType = 'draft'
          }
          if (firstUpdate?.status === 'sent') {
            tab = 'sent'
          }

          const channelIds = updates.map((update) => update?.profile_id)

          const postData = {
            tags: firstUpdate?.tags ?? [],
            channelIds,
          }

          const deepLink =
            updates.length === 1
              ? `/channels/${firstUpdate.profile_id}/posts/${firstUpdate.id}?tab=${tab}`
              : undefined

          const successMessage =
            successfulResponses.length > 1
              ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                successfulResponseMessageMap.get(queueingType).plural
              : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                successfulResponseMessageMap.get(queueingType).singular

          AppHooks.handleSavedDrafts({
            message: successMessage,
            fromIdea: !!ideaId,
            entityType,
            deepLink,
            postData,
          })

          if (AppStore.isExtension()) {
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ scope: NotificationScopes.UPDA... Remove this comment to see the full error message
            NotificationActionCreators.queueSuccess({
              scope: NotificationScopes.UPDATE_SAVING_AGGREGATE,
              message: successMessage,
            })
          }
        }

        // Queue individual notifications to let user know of individual
        // error/success states for each composer (we could be even more granular
        // here by, for each composer, singling out profile ids that either succeeded
        // or failed, and offering custom messages accordingly)
        successfulResponses.forEach((successfulResponse) => {
          AppActionCreators.clearComposerInlineErrors(
            successfulResponse.serviceName,
          )
          ComposerActionCreators.updateDraftHasSavingError(
            successfulResponse.serviceName,
            false,
          )
          ComposerActionCreators.updateDraftIsSaved(
            successfulResponse.serviceName,
          )
          NotificationActionCreators.queueSuccess({
            // @ts-expect-error TS(2322) FIXME: Type '`UPDATE_SAVING-${any}`' is not assignable to... Remove this comment to see the full error message
            scope: `${NotificationScopes.UPDATE_SAVING}-${successfulResponse.serviceName}`,
          })
        })

        // @ts-expect-error TS(7006) FIXME: Parameter 'unsuccessfulResponse' implicitly has an... Remove this comment to see the full error message
        unsuccessfulResponses.forEach((unsuccessfulResponse) => {
          AppActionCreators.clearComposerInlineErrors(
            unsuccessfulResponse.serviceName,
          )

          // Did we get an error because the user reached a limit and could upgrade?
          // Don't show it as an 'error' in that case
          if (unsuccessfulResponse.code === UpgradeErrorCodes.queueLimit) {
            const {
              showUpgradeToBusinessCta = false,
              showUpgradeToProCta = false,
            } = AppStore.getOrganizationsData()?.selected || {}

            const scope = `${NotificationScopes.PROFILE_QUEUE_LIMIT}-${unsuccessfulResponse.serviceName}`

            NotificationActionCreators.queueInfo({
              // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'Notificat... Remove this comment to see the full error message
              scope,
              // Remove the <a> from the response message for now until the backend stops returning it
              // since we already have a special setup for showing a `cta` button in the notification
              // TODO: Replace below with just `unsuccessfulResponse.message` when the API has removed
              // the link.
              message: removeLinkFromErrorMessageText(
                unsuccessfulResponse.message,
                'embedded-cta-link',
              ),
              isUnique: true,
              cta: {
                label: 'Upgrade',
                isUpgradePath: true,
                action: () => {
                  // not needed, since we set `isUpgradePath: true` above
                  // see publish-frontend/src/legacy/composer/composer/components/Notification.tsx
                  // which handles the action when the Upgrade button is clicked
                },
              },
            })
          } else {
            ComposerActionCreators.updateDraftHasSavingError(
              unsuccessfulResponse.serviceName,
              true,
            )

            const scope = FloatingErrorCodes.includes(unsuccessfulResponse.code)
              ? `${NotificationScopes.UPDATE_SAVING}-${ErrorTypes.FLOATING}`
              : `${NotificationScopes.UPDATE_SAVING}-${ErrorTypes.INLINE}-${unsuccessfulResponse.serviceName}`

            NotificationActionCreators.queueError({
              // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'Notificat... Remove this comment to see the full error message
              scope,
              message: unsuccessfulResponse.message,
            })
          }
        })
      })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  clearComposerInlineErrors: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_CLEAR_INLINE_ERRORS,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  createNewSubprofile: (profileId, name) => {
    const data = { name }

    WebAPIUtils.createNewSubprofile(profileId, data)
      // @ts-expect-error TS(7006) FIXME: Parameter 'newSubprofile' implicitly has an 'any' ... Remove this comment to see the full error message
      .then((newSubprofile) => {
        AppDispatcher.handleViewAction({
          actionType: ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE,
          profileId: newSubprofile.profileId,
          subprofileId: newSubprofile.id,
          avatar: newSubprofile.avatar,
          name: newSubprofile.name,
          isShared: newSubprofile.isShared,
        })
      })
      // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
      .catch(({ message }) => {
        AppDispatcher.handleViewAction({
          actionType: ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE_FAILED,
          profileId,
        })

        NotificationActionCreators.queueError({
          scope: NotificationScopes.BOARD_CREATION,
          data: { profileId },
          message,
        })
      })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  refreshSubprofileData: async (profileId) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
    await WebAPIUtils.fetchProfileSubprofiles(profileId).then((response) => {
      AppDispatcher.handleViewAction({
        actionType: ActionTypes.APP_REFRESH_SUBPROFILE_DATA,
        profileId,
        subprofileData: response.subprofiles,
      })
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  createSubprofilePending: (profileId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_CREATE_NEW_SUBPROFILE_PENDING,
      profileId,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'isLoading' implicitly has an 'any' type... Remove this comment to see the full error message
  setThumbnailLoading: (isLoading) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_SET_THUMBNAIL_LOADING,
      isLoading,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  unselectSubprofile: (profileId, subprofileId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_UNSELECT_SUBPROFILE,
      profileId,
      subprofileId,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  selectSubprofile: (profileId, subprofileId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_SELECT_SUBPROFILE,
      profileId,
      subprofileId,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  selectProfile: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SELECT_PROFILE,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unselectProfile: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UNSELECT_PROFILE,
      id,
    })
  },

  selectProfiles: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
    ids,
    markAppAsLoadedWhenDone = false,
    originatedFromGroupSelection = false,
  ) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SELECT_PROFILES,
      ids,
      markAppAsLoadedWhenDone,
      originatedFromGroupSelection,
    })
  },

  updateShortenerForProfile: (profileId: string, domain: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.UPDATE_SHORTENER_FOR_PROFILE,
      profileId,
      domain,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  unselectProfiles: (ids) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UNSELECT_PROFILES,
      ids,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  queueProfileSubprofilesDropdownsIdsToExpand: (ids) => {
    AppDispatcher.handleViewAction({
      actionType:
        ActionTypes.COMPOSER_QUEUE_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND,
      ids,
    })
  },

  emptyProfileSubprofilesDropdownsIdsToExpand: () => {
    AppDispatcher.handleViewAction({
      actionType:
        ActionTypes.COMPOSER_EMPTY_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND,
    })
  },

  selectProfilesOnBehalfOfUser: (
    ids: string[],
    markAppAsLoadedWhenDone?: boolean,
    originatedFromGroupSelection?: boolean,
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SELECT_PROFILES_ON_BEHALF_OF_USER,
      ids,
      markAppAsLoadedWhenDone,
      originatedFromGroupSelection,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  collapseProfileSubprofileDropdown: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_COLLAPSE_PROFILE_SUBPROFILE_DROPDOWN,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  expandProfileSubprofileDropdown: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_EXPAND_PROFILE_SUBPROFILE_DROPDOWN,
      id,
    })
  },

  profileDropdownHidden: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.PROFILE_DROPDOWN_HIDDEN,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  selectGroupProfiles: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_SELECT_GROUP_PROFILES,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unselectGroupProfiles: (id, selectedProfileGroupsIds) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_UNSELECT_GROUP_PROFILES,
      id,
      selectedProfileGroupsIds,
    })
  },

  getProfileSlotDataForMonth: (() => {
    const pendingAPICalls = new Set()

    // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
    return (profileId, momentInMonth) => {
      const startDateString = momentInMonth
        .startOf('month')
        .format('YYYY-MM-DD')
      const endDateString = momentInMonth.endOf('month').format('YYYY-MM-DD')
      const requestIdentifier = `${profileId}-${startDateString}-${endDateString}`

      // If a request for the same profile and month is already running, don't make another one
      if (pendingAPICalls.has(requestIdentifier)) return

      WebAPIUtils.getProfileSlotDataForDateRange(
        profileId,
        startDateString,
        endDateString,
        // @ts-expect-error TS(7006) FIXME: Parameter 'slots' implicitly has an 'any' type.
      ).then((slots) => {
        pendingAPICalls.delete(requestIdentifier)

        AppDispatcher.handleViewAction({
          actionType: ActionTypes.APP_RECEIVE_PROFILE_SLOT_DATA,
          id: profileId,
          slots,
        })
      })

      pendingAPICalls.add(requestIdentifier)
    }
  })(),

  toggleAllProfiles: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_TOGGLE_ALL_PROFILES,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'isEnabled' implicitly has an 'any' type... Remove this comment to see the full error message
  updateOmniboxState: (isEnabled) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_UPDATE_OMNIBOX_STATE,
      isEnabled,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'partiallySavedDrafts' implicitly has an... Remove this comment to see the full error message
  updatePartiallySavedDraftsProfilesIds: (partiallySavedDrafts) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_UPDATE_PARTIALLY_SAVED_DRAFTS_PROFILES_IDS,
      partiallySavedDrafts,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'modalKey' implicitly has an 'any' type.
  rememberModalView: (modalKey) => WebAPIUtils.rememberModalView(modalKey),

  forceEditorFocus: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_FORCE_FOCUS,
    })
  },

  stopForcingEditorFocus: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_STOP_FORCE_FOCUS,
    })
  },

  markAppAsLoaded: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_LOADED,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profilesData' implicitly has an 'any' t... Remove this comment to see the full error message
  resetSelectedProfiles: (profilesData) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.RESET_PROFILES_DATA,
      profilesData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'idsToSelect' implicitly has an 'any' ty... Remove this comment to see the full error message
  updateSelectedProfiles: (idsToSelect, idsToUnselect) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.UPDATE_SELECTED_PROFILES,
      idsToSelect,
      idsToUnselect,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  getFacebookDomainOwnershipForProfile: (profileId, url) => {
    WebAPIUtils.getFacebookDomainOwnershipForProfile(profileId, url).then(
      // @ts-expect-error TS(7006) FIXME: Parameter 'isOwner' implicitly has an 'any' type.
      (isOwner) => {
        AppDispatcher.handleApiAction({
          actionType: ActionTypes.APP_RECEIVE_FACEBOOK_DOMAIN_OWNERSHIP_DATA,
          profileId,
          url,
          isOwner,
        })
      },
    )
  },

  refreshFacebookDomainOwnershipData: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.APP_REFRESH_FACEBOOK_DOMAIN_OWNERSHIP_DATA,
    })
  },

  rememberTwitterMaxProfileNotificationClosedOnce: () => {
    AppDispatcher.handleViewAction({
      actionType:
        ActionTypes.APP_REMEMBER_TWITTER_MAX_PROFILE_NOTIF_CLOSED_ONCE,
    })
  },
}

export default AppActionCreators
