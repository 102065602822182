import React from 'react'
import { RadioCard, Flex, Text, Heading } from '@buffer-mono/popcorn'
import type { Suggestion } from '../../hooks/useCreateFeed'
import styles from './SuggestedFeedCard.module.css'

type Props = {
  suggestion: Suggestion
}

export const SuggestedFeedCard = (props: Props): JSX.Element => {
  return (
    <RadioCard value={props.suggestion.url} className={styles.radioCard}>
      <Flex direction="column" fullWidth>
        <Heading size="small" className={styles.title}>
          {props.suggestion.title}
        </Heading>
        <Text truncate size="sm" color="subtle" className={styles.url}>
          {props.suggestion.url}
        </Text>
      </Flex>
    </RadioCard>
  )
}
