import {
  ChevronRightIcon,
  Collapsible,
  DropdownMenu,
  EllipsisIcon,
  FolderIcon,
  FolderOpenIcon,
  IconButton,
  PencilIcon,
  PlusIcon,
  Sidebar,
  useSidebar,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { graphql, readFragment, type ResultOf } from '~publish/graphql'
import { feedCollectionPage } from '~publish/legacy/routes'
import { DeleteDropdownMenuItem } from './DeleteConfirmationDialog'
import { FeedCollectionNameEditor } from './FeedCollectionNameEditor'
import { SidebarFeedItem_Feed } from './SidebarFeedItem'
import styles from './SidebarFeedNav.module.css'
import { SubscribeToFeedDialogDropdownMenuItem } from '../SubscribeToFeedDialog/SubscribeToFeedDialogDropdownMenuItem'
import { ImportFeedsDialogDropdownMenuItem } from '../ImportFeedsDialog/ImportFeedsDialogDropdownMenuItem'
import { ExploreFeedsDialogDropdownMenuItem } from '../ExploreFeedsDialog/ExploreFeedsDialogDropdownMenuItem'
import { Limits, type FeedCTA } from '../../constants'
import type { WrappedCreateFeedResponse } from '../../hooks/useCreateFeed'
import { isNotNull, isOfType } from '~publish/helpers/typeGuards'

const SidebarCollectionIcon = ({
  canExpand,
  isHovering,
  isExpanded,
  onExpand,
}: {
  canExpand: boolean
  isHovering: boolean
  isExpanded: boolean
  onExpand: (e: React.MouseEvent) => void
}): JSX.Element => {
  if (!canExpand) {
    return <FolderIcon />
  }

  if (isHovering) {
    return (
      <Collapsible.Trigger asChild>
        <IconButton
          label={isExpanded ? 'Collapse collection' : 'Expand collection'}
          size="small"
          variant="tertiary"
          onClick={onExpand}
        >
          <ChevronRightIcon
            className={clsx(styles.sidebarChevron, {
              [styles.open]: isExpanded,
              [styles.closed]: !isExpanded,
            })}
          />
        </IconButton>
      </Collapsible.Trigger>
    )
  }
  return isExpanded ? <FolderOpenIcon /> : <FolderIcon />
}

export const SidebarCollectionItem_Collection = graphql(
  /* GraphQL */ `
    fragment SidebarCollectionItem_Collection on FeedCollection {
      id
      name
      feeds {
        id
        ...SidebarFeedItem_Feed
      }
    }
  `,
  [SidebarFeedItem_Feed],
)

type SidebarCollectionItemProps = {
  collection: ResultOf<typeof SidebarCollectionItem_Collection>
  children?: React.ReactNode
  tier: 'free' | 'paid'
  onAddFeed: (args: {
    feedUrl: string
    collectionId: string
    cta: FeedCTA
  }) => Promise<WrappedCreateFeedResponse>
  onRemoveFeed: (feedId: string) => Promise<void>
  onUpgrade: (args: { cta: CTA; upgradePathName: string }) => void
  onRename: (collectionId: string, value: string) => void
  onDelete: (collectionId: string) => void
}

export const SidebarCollectionItem = ({
  collection,
  children,
  tier,
  onAddFeed,
  onRemoveFeed,
  onUpgrade,
  onRename,
  onDelete,
}: SidebarCollectionItemProps): JSX.Element => {
  const { feedCollectionId, feedId } = useParams<{
    feedCollectionId?: string
    feedId?: string
  }>()
  const location = useLocation()
  const { state } = useSidebar()
  const previousSidebarState = React.useRef(state)

  const canExpand = state === 'expanded'

  const [isEditing, setIsEditing] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)

  const collectionData = readFragment(
    SidebarCollectionItem_Collection,
    collection,
  )

  const isSelected = feedCollectionId === collectionData.id && !feedId

  const feeds = readFragment(SidebarFeedItem_Feed, collectionData.feeds)
  /**
   * Filter out any non-RSS feeds to use for reference of existing feeds when adding new feeds
   */
  const existingRssFeeds = feeds
    ?.map((feed) => {
      return isOfType(feed.connectionMetadata, 'RSSFeedConnectionMetadata')
        ? { id: feed.id, url: feed.connectionMetadata.url }
        : null
    })
    .filter(isNotNull)

  const limitReached = feeds?.length >= Limits.feeds[tier].perCollection
  const totalRemainingFeeds =
    Limits.feeds[tier].perCollection - (feeds?.length ?? 0)

  const isActiveCollectionFromLocation = React.useMemo(() => {
    return (
      location.pathname.includes(collectionData.id) ||
      feeds?.some((feed) => location.pathname.includes(feed.id))
    )
  }, [location.pathname, collectionData.id, feeds])

  // Use initial state to determine whether the collection is expanded
  // in combination with onClick handler below to trigger expansion on navigate.
  // Using this pattern instead of useEffect ensures we don't accidentally
  // auto-expand the collection when a user has deliberately collapsed it.
  const [isExpanded, setIsExpanded] = React.useState(() => {
    return isActiveCollectionFromLocation
  })

  /**
   * Collapse all collections when the sidebar is collapsed
   */
  React.useEffect(() => {
    if (!canExpand) {
      setIsExpanded(false)
    }
  }, [canExpand])

  /**
   * Expand only active collection when the sidebar switches from collapsed to expanded
   */
  React.useEffect(() => {
    if (
      canExpand &&
      previousSidebarState.current === 'collapsed' &&
      state === 'expanded' &&
      isActiveCollectionFromLocation
    ) {
      setIsExpanded(true)
    }
  }, [canExpand, state, isActiveCollectionFromLocation])

  /**
   * Update previous state
   * Needs to run after above useEffect to ensure state doesn't update before it has been checked
   */
  React.useEffect(() => {
    previousSidebarState.current = state
  }, [state])

  /**
   * If the currently active feed item is moved to a collection, that collections feeds will be updated
   * This will trigger a re-render and isActiveCollectionFromLocation will be re-evaluated
   * This will trigger the useEffect above and expand the collection
   */
  React.useEffect(() => {
    if (isActiveCollectionFromLocation && canExpand) {
      setIsExpanded(true)
    }
  }, [isActiveCollectionFromLocation, canExpand])

  const handleAddFeed = async (args: {
    feedUrl: string
    cta: FeedCTA
  }): Promise<WrappedCreateFeedResponse> => {
    return onAddFeed({ ...args, collectionId: collectionData.id })
  }

  const handleRename = (value: string): void => {
    onRename(collectionData.id, value)
    setIsEditing(false)
    setIsHovering(false)
  }

  const toggleExpand = (e: React.MouseEvent): void => {
    e.stopPropagation()
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  if (isEditing) {
    return (
      <FeedCollectionNameEditor
        key={collectionData.id}
        icon={isExpanded ? <FolderOpenIcon /> : <FolderIcon />}
        defaultValue={collectionData.name}
        onUpdate={handleRename}
        onClose={(): void => setIsEditing(false)}
      />
    )
  }

  return (
    <Collapsible open={isExpanded} onOpenChange={setIsExpanded}>
      <Sidebar.ListItem className={styles.listItem}>
        <Sidebar.Button
          key={collectionData.id}
          as={NavLink}
          to={feedCollectionPage.linkTo(collectionData.id)}
          indent={1}
          className={styles.sidebarLink}
          selected={isSelected}
          exact
          onMouseEnter={(): void => setIsHovering(true)}
          onMouseLeave={(): void => setIsHovering(false)}
          onClick={(): void => {
            if (canExpand) {
              setIsExpanded(true)
            }
          }}
          prefix={
            <SidebarCollectionIcon
              canExpand={canExpand}
              isHovering={isHovering}
              isExpanded={isExpanded}
              onExpand={toggleExpand}
            />
          }
        >
          {collectionData.name}
        </Sidebar.Button>
        {state === 'expanded' && (
          <DropdownMenu>
            <DropdownMenu.Trigger>
              <IconButton
                label="More"
                size="medium"
                variant="tertiary"
                className={styles.listItemHoverButton}
              >
                <EllipsisIcon />
              </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  <PlusIcon />
                  Add
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubMenu
                  className={styles.moveToCollectionSubMenu}
                >
                  <SubscribeToFeedDialogDropdownMenuItem
                    tier={tier}
                    limitReached={limitReached}
                    existingFeeds={existingRssFeeds}
                    onAddFeed={handleAddFeed}
                    onUpgrade={onUpgrade}
                  />
                  <ImportFeedsDialogDropdownMenuItem
                    tier={tier}
                    limitReached={limitReached}
                    existingFeeds={existingRssFeeds}
                    remainingFeedsCount={totalRemainingFeeds}
                    onAddFeed={handleAddFeed}
                    onUpgrade={onUpgrade}
                    onRemoveFeed={onRemoveFeed}
                  />
                  <DropdownMenu.Separator />
                  <ExploreFeedsDialogDropdownMenuItem
                    existingFeeds={existingRssFeeds}
                    tier={tier}
                    limitReached={limitReached}
                    onAddFeed={handleAddFeed}
                    onUpgrade={onUpgrade}
                    onRemoveFeed={onRemoveFeed}
                  />
                </DropdownMenu.SubMenu>
              </DropdownMenu.Sub>
              <DropdownMenu.Item onClick={(): void => setIsEditing(true)}>
                <PencilIcon />
                Rename
              </DropdownMenu.Item>
              <DeleteDropdownMenuItem
                mode="collection"
                onConfirm={(): void => onDelete(collectionData.id)}
              />
            </DropdownMenu.Content>
          </DropdownMenu>
        )}
      </Sidebar.ListItem>
      <Collapsible.Content asChild>{children}</Collapsible.Content>
    </Collapsible>
  )
}
