import React from 'react'
import {
  Flex,
  Skeleton,
  Card,
  Text,
  Button,
  AchIcon,
  CreditCardIcon,
  AmazonPayIcon,
  AlipayIcon,
  CashappIcon,
  Notice,
  InfoIcon,
} from '@buffer-mono/popcorn'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'
import styles from '../Settings.module.css'
import {
  useGetPortalSession,
  PortalOptions,
} from '../hooks/useGetPortalSession'
import { getSettingsUrl } from '../util/getSettingsUrl'
import { capitalize } from '~publish/helpers/capitalize'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'

export const PaymentDetails_Billing = graphql(/* GraphQL */ `
  fragment PaymentDetails_Billing on Billing {
    paymentDetails {
      hasPaymentDetails
      creditCard {
        brand
        last4
        expMonth
        expYear
      }
      paymentMethod
    }
  }
`)

const paymentMethodNames = new Map<string, string>([
  ['bank', 'Bank Account'],
  ['link', 'Link'],
  ['amazonPay', 'Amazon Pay'],
  ['alipay', 'Alipay'],
  ['cashApp', 'Cash App'],
])

const formatPaymentMethodName = (paymentMethod: string): string => {
  return paymentMethodNames.get(paymentMethod) ?? capitalize(paymentMethod)
}

const PaymentMethodIcon = ({
  paymentMethod,
}: {
  paymentMethod: string | null
}): JSX.Element => {
  if (paymentMethod === 'bank') {
    return <AchIcon size="medium" />
  }
  if (paymentMethod === 'link') {
    // TODO: update with link icon once available
    return <CreditCardIcon size="medium" />
  }
  if (paymentMethod === 'amazonPay') {
    return <AmazonPayIcon size="medium" />
  }
  if (paymentMethod === 'alipay') {
    return <AlipayIcon size="medium" />
  }
  if (paymentMethod === 'cashApp') {
    return <CashappIcon size="medium" />
  }
  return <CreditCardIcon size="medium" />
}

const CreditCardDetails = ({
  brand,
  last4,
  expMonth,
  expYear,
}: {
  brand?: string
  last4?: string
  expMonth?: string
  expYear?: string
}): JSX.Element => {
  // TODO: can we make these non-nullable in the schema?
  if (!brand || !last4 || !expMonth || !expYear) {
    return <Text>Card</Text>
  }
  return (
    <>
      <Flex gap="xs" direction="row" align="center">
        <PaymentMethodIcon paymentMethod={'card'} />
        <Text>
          {capitalize(brand)} ... ... ... {last4}
        </Text>
      </Flex>
      <Flex gap="xs" direction="row" align="center">
        <Text>
          Expires {expMonth}/{expYear}
        </Text>
      </Flex>
    </>
  )
}

export const PaymentDetails = ({
  paymentPastDue,
  canManageBilling,
  canAddPaymentDetails,
  loading,
  data,
}: {
  paymentPastDue: boolean
  canManageBilling: boolean
  canAddPaymentDetails: boolean
  loading: boolean
  data?: FragmentOf<typeof PaymentDetails_Billing> | null
}): JSX.Element => {
  const paymentDetails = data
    ? readFragment(PaymentDetails_Billing, data).paymentDetails
    : null

  const { getCustomerPortalSession, loading: portalSessionLoading } =
    useGetPortalSession({
      portalOption: PortalOptions.MAIN_PAGE,
    })

  const isCreditCard = paymentDetails && !!paymentDetails.creditCard
  const showPaymentMethod = paymentDetails && paymentDetails.hasPaymentDetails

  return (
    <>
      {!canManageBilling && (
        <Notice>
          <Text>
            Please confirm your email address to update your payment method.
            Adding a payment method allows you to use advanced features or add
            more channels. You can visit our{' '}
            <a
              href={`https://support.buffer.com/article/504-verifying-your-buffer-email-address?${HC_UTM_PARAMS}`}
            >
              support site
            </a>{' '}
            for more information.
          </Text>
        </Notice>
      )}
      {paymentPastDue && (
        <Notice variant="warning">
          <Text>
            Your payment method is past due. Please update your payment method
            to continue using Buffer.
          </Text>
        </Notice>
      )}
      <Flex gap="xs" direction="row" align="center" justify="between" fullWidth>
        <Text size="lg" weight="bold">
          Payment Method
        </Text>
        <Button
          size="medium"
          variant="secondary"
          onClick={(): void => {
            getCustomerPortalSession(getSettingsUrl())
          }}
          disabled={
            portalSessionLoading || !canManageBilling || !canAddPaymentDetails
          }
        >
          {showPaymentMethod ? 'Update Method' : 'Add Method'}
        </Button>
      </Flex>
      <Skeleton show={loading}>
        <Card className={styles.fullWidth}>
          {paymentDetails && paymentDetails.hasPaymentDetails ? (
            <Flex
              gap="xs"
              direction="row"
              align="center"
              justify="between"
              fullWidth
            >
              {isCreditCard ? (
                <CreditCardDetails
                  brand={paymentDetails.creditCard?.brand}
                  last4={paymentDetails.creditCard?.last4}
                  expMonth={paymentDetails.creditCard?.expMonth}
                  expYear={paymentDetails.creditCard?.expYear}
                />
              ) : (
                <Flex gap="xs" direction="row" align="center">
                  <PaymentMethodIcon
                    paymentMethod={paymentDetails.paymentMethod}
                  />
                  <Text>
                    {/* TODO: can we make this non-nullable in the schema? */}
                    {paymentDetails.paymentMethod &&
                      formatPaymentMethodName(paymentDetails.paymentMethod)}
                  </Text>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex gap="xs" direction="row" align="center">
              <InfoIcon size="medium" />
              <Text>
                Add a payment method so you can use advanced features or more
                channels. This payment method will be available for all team
                members with purchasing rights to use.
              </Text>
            </Flex>
          )}
        </Card>
      </Skeleton>
    </>
  )
}

export default PaymentDetails
