import React, { useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import type { ApolloError } from '@apollo/client'

import {
  DndContext,
  type DragEndEvent,
  type DragStartEvent,
  DragOverlay,
  pointerWithin,
  rectIntersection,
  type CollisionDetection,
  type DragOverEvent,
  type DragCancelEvent,
} from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import {
  Button,
  CalendarClockIcon,
  CriticalIcon,
  EmptyState,
  PlusIcon,
  Text,
  toast,
} from '@buffer-mono/popcorn'
import { Link } from 'react-router-dom'
import { isPast } from 'date-fns'

import { DateSeparator } from '~publish/components/DateSeparator'
import { PostCard, PostCard_Post } from '~publish/components/PostCard'
import { graphql, getFragmentData, type FragmentType } from '~publish/gql'
import type { PostCard_PostFragment } from '~publish/gql/graphql'
import {
  isPausedPost,
  isScheduledPost,
  postStatusByTab,
} from '~publish/helpers/post'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import { useTimezone } from '~publish/hooks/useTimezone'
import {
  POSTS_PER_PAGE,
  usePaginatedPostList,
} from '~publish/pages/AllChannels/PostList/usePaginatedPostList'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { useCustomSensors } from '~publish/hooks/useCustomSensors'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { PostListSkeleton } from '~publish/pages/AllChannels/PostList'
import { DROP_ANIMATION } from '~publish/helpers/dndkit/constants'
import { PostingTimeline } from '~publish/components/PostingTimeline'
import { usePostDraggedTracking } from '~publish/hooks/usePostDraggedTracking'
import { useSlotsEnabled } from '~publish/hooks/useSlotsEnabled'
import {
  PostingGoalSlotsTour,
  usePostingGoalsSplit,
} from '~publish/components/PostingGoalSlotsTour'

import {
  generatePostingSlots,
  mergePostsAndSlots,
  type Slot,
} from './postSlotManager'
import { DraggablePostCard } from './DraggablePostCard'
import { useDropPostMutation } from './useDropPostMutation'
import { useSwapPostsMutation } from './useSwapPostsMutation'
import { SlotOrPostCard } from './SlotOrPostCard'

import styles from './QueueList.module.css'

export const QueueList_Channel = graphql(/* GraphQL */ `
  fragment QueueList_Channel on Channel {
    id
    name
    service
    accessLevel
    isQueuePaused
    timezone
    postingSchedule {
      day
      times
      paused
    }
    postingGoal {
      goal
    }
  }
`)

const ErrorMessage = ({ error }: { error: ApolloError }): JSX.Element => {
  const isPermissionError = error.message.includes(
    'Actor can not access the specified channels.',
  ) // When the user has not been granted permission to the channel

  if (isPermissionError) {
    return (
      <EmptyState variant="critical" size="large">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Access Denied</EmptyState.Heading>
        <EmptyState.Description>
          You can not access the specified channel. Try reaching out to your
          organization owner to make sure you have access to this channel.
        </EmptyState.Description>
      </EmptyState>
    )
  }

  return (
    <EmptyState variant="critical" size="large">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Error happened, let our team know</EmptyState.Heading>
      <EmptyState.Description>
        Please let our team know about it, we&apos;ll fix it as soon as
        possible. <Text color="critical">{error.message}</Text>
      </EmptyState.Description>
    </EmptyState>
  )
}

const isPost = (item: unknown): item is PostCard_PostFragment => {
  return (
    typeof item === 'object' &&
    item !== null &&
    '__typename' in item &&
    item.__typename === 'Post'
  )
}

type QueueListProps = {
  channel: FragmentType<typeof QueueList_Channel>
  tagIds?: string[]
}

export const QueueList = ({
  channel: passedChannel,
  tagIds,
}: QueueListProps): JSX.Element => {
  const timezone = useTimezone()
  const channel = getFragmentData(QueueList_Channel, passedChannel)
  const isPostingGoalEnabled = usePostingGoalsSplit()

  const isDragAndDropEnabled =
    !channel.isQueuePaused && channel.accessLevel === 'fullPosting'

  const {
    data,
    loading,
    error,
    lastElementRef: lastPostRef,
    fetchingMore,
  } = usePaginatedPostList({
    status: postStatusByTab.queue,
    channelIds: [channel.id],
    // Note - we do NOT filter out
    // posts by tagIds as we need to know if a post slot is occupied
    // Filtering is done in the SlotOrPostCard component by visually
    // hiding the post
    tagIds: [],
  })

  const flattenedPostEdges = data?.posts?.edges?.map((edge) => edge.node) ?? []
  const posts = getFragmentData(PostCard_Post, flattenedPostEdges)
  const [numberOfSlots, setNumberSlots] = React.useState<number>(POSTS_PER_PAGE)
  const [lastSlotRef] = useInfiniteScrollPagination({
    loading: false,
    hasNextPage: true,
    fetchMore: async () => setNumberSlots((prev) => prev + POSTS_PER_PAGE),
    rootMargin: '500px',
  })
  const [activeDraggablePostId, setActiveDraggablePostId] = useState<
    string | null
  >(null)
  const feedRef = useRef<HTMLDivElement>(null)
  const [dropPostInQueue] = useDropPostMutation()
  const [swapPostsInQueue] = useSwapPostsMutation()
  const dateTimeFormatter = useDateTimeFormatter()
  const sensors = useCustomSensors()
  const trackPostDrag = usePostDraggedTracking()
  const [showTimeSlots] = useSlotsEnabled()

  const clickSetPostingGoal = (): void => {
    if (!channel) return
    const { actions } = window.appshell ?? {}
    actions.openModal('postingGoalConfiguration', {
      channelId: channel.id,
      source: 'channel-page',
    })
  }

  const isFilterActive = !!tagIds?.length
  const isPostingScheduleEmpty =
    !channel.postingSchedule?.length ||
    channel.postingSchedule.every((slot) => !slot.times.length || slot.paused)
  const hasPosts = Boolean(posts?.length)
  const hasVisibleTimeSlots = !isPostingScheduleEmpty && showTimeSlots
  const shouldShowEmptyState =
    !hasPosts && !hasVisibleTimeSlots && !isFilterActive

  const pausedPosts = posts.filter(isPausedPost)
  const scheduledPosts = posts.filter(isScheduledPost)

  const hasNextPage = data?.posts.pageInfo.hasNextPage
  const lastPostDueAt = scheduledPosts?.[scheduledPosts.length - 1]?.dueAt
  const scheduleSlots = generatePostingSlots({
    postingSchedule: channel.postingSchedule,
    timezone,
    endDate: lastPostDueAt,
    hasNextPage,
    numberOfSlots,
  })

  const postsAndSlots = mergePostsAndSlots({
    posts: scheduledPosts,
    slots: showTimeSlots ? scheduleSlots : [],
    callback: () => {
      if (showTimeSlots && scheduleSlots.length > numberOfSlots) {
        setNumberSlots(scheduleSlots.length)
      }
    },
  })

  const firstSlot = useMemo(() => {
    return postsAndSlots.find((item) => !isPost(item)) as Slot
  }, [postsAndSlots])

  const announcements = {
    onDragStart({ active }: DragStartEvent): string {
      if (!active.data.current?.dueAt) return ``
      return `Picked up post scheduled for ${dateTimeFormatter(
        active.data.current.dueAt,
      )}.`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      if (!active.data.current?.dueAt || !over?.id || over.id === active.id)
        return ``

      if (over) {
        if (over.data.current?.type === 'slot') {
          return `Post scheduled for ${dateTimeFormatter(
            active.data.current.dueAt,
          )} is over empty slot at ${dateTimeFormatter(
            over.data.current.date as string,
          )}.`
        } else {
          if (over.data.current?.type === 'post') {
            return `Post scheduled for ${dateTimeFormatter(
              active.data.current.dueAt,
            )}  is over another post scheduled for ${dateTimeFormatter(
              over.data.current.dueAt,
            )}. Dropping will swap the posts.`
          }
        }
      }
      return `Post is no longer over a droppable area.`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      if (!active.data.current?.dueAt) return ``
      if (over?.id === active.id)
        return `Post scheduled for ${dateTimeFormatter(
          active.data.current.dueAt,
        )} was moved to its original position.`

      if (over) {
        if (over.data.current?.type === 'slot') {
          return `Post scheduled for ${dateTimeFormatter(
            active.data.current.dueAt,
          )} was rescheduled to ${dateTimeFormatter(over.id as string)}.`
        } else {
          if (over.data.current?.type === 'post') {
            return `Post scheduled for ${dateTimeFormatter(
              active.data.current.dueAt,
            )} was swapped with post scheduled for ${dateTimeFormatter(
              active.data.current.dueAt,
            )}`
          }
        }
      }
      return `Post was dropped back in its original position.`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      if (!active.data.current?.dueAt) return ``

      return `Dragging was cancelled. Post scheduled for ${dateTimeFormatter(
        active.data.current.dueAt,
      )} was returned to its original position.`
    },
  }

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveDraggablePostId(event.active.id as string)
    const post = posts.find((p) => p.id === event.active.id)
    if (post) trackPostDrag(post)
  }

  const handleDragEnd = async (event: DragEndEvent): Promise<void> => {
    const { active, over } = event

    setActiveDraggablePostId(null)
    const isSlotDrop = over?.data?.current?.type === 'slot'
    if (over && active.id !== over.id && isSlotDrop) {
      const draggedPostId = active.id as string
      const newSlotDate = over.id as string

      const dropPostResult = await dropPostInQueue({
        variables: { input: { id: draggedPostId, dueAt: newSlotDate } },
      })

      if (dropPostResult.success) {
        toast.success('Post rescheduled successfully')
      } else {
        toast.error(
          `Failed to reschedule post, ${dropPostResult.error.message}`,
        )
      }
    }

    if (over && active.id !== over.id && !isSlotDrop) {
      const draggedPostId = active.id as string
      const overPostId = over.id as string

      const swapPostsResult = await swapPostsInQueue({
        variables: {
          input: { sourceId: draggedPostId, targetId: overPostId },
        },
      })

      if (swapPostsResult.success) {
        toast.success('Posts swapped successfully')
      } else {
        toast.error(swapPostsResult.error.message)
      }
    }
  }

  if (loading) {
    return <PostListSkeleton />
  }

  if (error && !data) {
    return <ErrorMessage error={error} />
  }

  // no posts in general
  if (shouldShowEmptyState) {
    return (
      <EmptyState size="large" variant="primary">
        <EmptyState.Icon>
          <CalendarClockIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>No posts scheduled</EmptyState.Heading>
        <EmptyState.Description>
          Schedule some posts and they will appear here
        </EmptyState.Description>
        <EmptyState.Actions>
          {channel.postingGoal && isPostingScheduleEmpty && (
            <Button
              size="large"
              variant="secondary"
              as={Link}
              to={`/channels/${channel.id}/settings?tab=posting-schedule`}
            >
              Set Posting Schedule
            </Button>
          )}
          {!channel.postingGoal && (
            <Button
              size="large"
              variant="secondary"
              onClick={clickSetPostingGoal}
            >
              Set a Posting Goal
            </Button>
          )}
          <NewPostComposerTrigger
            cta="publish-queue-emptyState-newPost-1"
            channels={[channel.id]}
          >
            <Button size="large">
              <PlusIcon /> New post
            </Button>
          </NewPostComposerTrigger>
        </EmptyState.Actions>
      </EmptyState>
    )
  }

  return (
    <>
      {isPostingGoalEnabled && <PostingGoalSlotsTour />}
      <DndContext
        autoScroll={true}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        collisionDetection={collisionDetectionAlgorithm}
        sensors={sensors}
        modifiers={[restrictToFirstScrollableAncestor]}
        accessibility={{
          announcements,
        }}
      >
        <PostingTimeline
          role="feed"
          aria-busy={fetchingMore}
          data-testid="queue-list"
          ref={feedRef}
        >
          {pausedPosts.length > 0 && (
            <>
              <PostingTimeline.Header>Not Published</PostingTimeline.Header>
              {pausedPosts.map((post) => (
                <PostingTimeline.Entry key={post.id}>
                  <PostingTimeline.TimeLabel
                    date={post.dueAt}
                    status={post.status}
                    notificationStatus={post.notificationStatus}
                    schedulingType={post.schedulingType}
                    includeDate
                    customScheduled={post.isCustomScheduled}
                    overdue={isPast(post.dueAt) && post.error === null}
                  />
                  <PostingTimeline.Content>
                    {
                      // Leaving the "DraggablePostCard" for now as we'll probably
                      // want to enable drag and drop for paused posts in the future
                      isDragAndDropEnabled &&
                      post.allowedActions.includes('updatePost') ? (
                        <DraggablePostCard
                          post={post as FragmentType<typeof PostCard_Post>}
                        />
                      ) : (
                        <PostCard
                          post={post as FragmentType<typeof PostCard_Post>}
                        />
                      )
                    }
                  </PostingTimeline.Content>
                </PostingTimeline.Entry>
              ))}
            </>
          )}
          {postsAndSlots.map((item, index, list) => {
            const currentDate = isPost(item) ? item.dueAt : item.date
            const prevItem = index > 0 ? list[index - 1] : undefined
            const prevDate = isPost(prevItem) ? prevItem?.dueAt : prevItem?.date
            const isFirstSlot = !isPost(item) && item.date === firstSlot?.date

            const extraProps = isFirstSlot ? { 'data-tour-id': 'slot' } : {}

            return (
              <React.Fragment key={isPost(item) ? item.id : item.date}>
                <DateSeparator
                  currentDate={currentDate}
                  previousDate={prevDate}
                />
                <SlotOrPostCard
                  item={item}
                  tagIds={tagIds ?? []}
                  channelId={channel.id}
                  isDragAndDropEnabled={isDragAndDropEnabled}
                  {...extraProps}
                />
              </React.Fragment>
            )
          })}

          {(hasNextPage || showTimeSlots) && (
            <PostingTimeline.Loading
              ref={hasNextPage ? lastPostRef : lastSlotRef}
            />
          )}
        </PostingTimeline>
        {createPortal(
          <DragOverlay
            dropAnimation={DROP_ANIMATION}
            className={styles.dragOverlay}
          >
            {activeDraggablePostId && (
              <DraggablePostCard
                post={
                  posts.find(
                    (post) => post.id === activeDraggablePostId,
                  ) as FragmentType<typeof PostCard_Post>
                }
                inOverlay={true}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </>
  )
}

const collisionDetectionAlgorithm: CollisionDetection = (args) => {
  const pointerCollisions = pointerWithin(args)

  if (pointerCollisions.length > 0) {
    return pointerCollisions
  }

  return rectIntersection(args)
}
