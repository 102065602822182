import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { useQuery, type QueryResult, gql } from '@apollo/client'

import {
  Popover,
  Button,
  VisuallyHidden,
  Drawer,
  Sidebar,
  Badge,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../common/context/User'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'
import trackStreaksButtonClicked from '../../../../tracking/trackStreaksButtonClicked'
import { AppshellEventKeys } from '../../../../common/events/types'

import StreakIcon from './images/StreakIcon'
import { StreakCoachMark } from './components/StreakCoachMark'
import { OnTrackRing } from './components/OnTrackRing'
import { StreakWidgetContent } from './components/StreakWidgetContent'

import styles from './StreakWidget.module.css'

const WEEKLY_POSTING_STREAK_QUERY = gql`
  query WeeklyPostingStreak($input: WeeklyPostingStreakInput!) {
    weeklyPostingStreak(input: $input) {
      count
      status
      periodsAhead
      previousCount
      longestCount
      userStreakState
    }
  }
`

export interface WeeklyPostingStreakData {
  weeklyPostingStreak: {
    count: number
    status: 'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed'
    periodsAhead?: number
    previousCount?: number
    longestCount?: number
    userStreakState: string
  } | null
}

interface WeeklyPostingStreakVars {
  input: {
    organizationId: string
  }
}

export const useWeeklyPostingStreak = (
  organizationId: string | undefined,
): QueryResult<WeeklyPostingStreakData, WeeklyPostingStreakVars> => {
  return useQuery<WeeklyPostingStreakData, WeeklyPostingStreakVars>(
    WEEKLY_POSTING_STREAK_QUERY,
    {
      variables: {
        input: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error - organizationId may be undefined query is skipped if it is
          organizationId,
        },
      },
      skip: !organizationId,
    },
  )
}

const usePreviousStreakStatus = (
  status?: 'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed',
): 'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed' | undefined => {
  const ref = useRef<
    'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed' | undefined
  >(undefined)
  useEffect(() => {
    ref.current = status
  }, [status])
  return ref.current
}

const StreakWidget = ({
  isMobile = false,
  handleOnClose,
}: {
  isMobile?: boolean
  handleOnClose?: () => void
}): JSX.Element | null => {
  const { isActive: onboardPending } = useDismissBanner({
    banner: BannerTypes.streakCoachMark,
  })
  const [open, setOpen] = useState(false)
  const user = useUser()
  const {
    data: { weeklyPostingStreak: streak } = {},
    refetch: refetchStreakQuery,
  } = useWeeklyPostingStreak(user.currentOrganization?.id)
  const initialStreakStatus = usePreviousStreakStatus(streak?.status)

  // Listen for post creation events to refetch the streak query with each new post
  useEffect(() => {
    const debouncedRefetch = (): void => {
      const debounced = debounce(refetchStreakQuery, 5000)
      if (streak && streak.status !== 'Hit') debounced()
    }

    window.addEventListener(
      AppshellEventKeys.STREAK_UPDATE_EVENT_KEY,
      debouncedRefetch,
    )

    return function cleanup(): void {
      window.removeEventListener(
        AppshellEventKeys.STREAK_UPDATE_EVENT_KEY,
        debouncedRefetch,
      )
    }
  }, [streak, refetchStreakQuery])

  useEffect(() => {
    if (streak) {
      if (
        (initialStreakStatus === 'Started' ||
          initialStreakStatus === 'Missed') &&
        (streak.status === 'OnTrack' || streak.status === 'Hit')
      )
        setOpen(true)
    }
  }, [streak, initialStreakStatus])

  if (!streak) return null

  const statusClass = styles[streak.status.toLowerCase()]

  const trackOnClick = (): void => {
    trackStreaksButtonClicked({
      user,
      payload: {
        ctaView: 'appShell',
        ctaButton: 'streakWidget',
        ctaLocation: 'navbar',
        ctaVersion: '1',
        streakStatus: streak.status,
        streakCount: streak.count,
      },
    })
  }

  const showStreakCount =
    !(streak.status === 'Started' && streak.count === 0) && !onboardPending
  const showOnTrackRing = streak.status === 'OnTrack' && !onboardPending

  if (isMobile) {
    return (
      <Drawer>
        <Drawer.Trigger>
          <Sidebar.Button
            size="large"
            className={clsx(
              styles.triggerButton,
              showOnTrackRing && styles.hasOnTrackRingPadding,
            )}
          >
            <div
              className={clsx(
                styles.streakWidgetContainer,
                showOnTrackRing && styles.hasOnTrackRing,
              )}
            >
              {showOnTrackRing && (
                <OnTrackRing className={styles.onTrackRing} />
              )}
              <StreakIcon size={16} />
            </div>
            Streak
            {showStreakCount && (
              <Badge
                size="small"
                className={clsx(styles.widgetCountMobile, statusClass)}
              >
                {streak.count}
              </Badge>
            )}
          </Sidebar.Button>
        </Drawer.Trigger>
        <Drawer.Content handle={false}>
          <Drawer.Body className={styles.drawerContent}>
            <StreakWidgetContent
              streak={streak}
              onClose={(): void => {
                setOpen(false)
                handleOnClose?.()
              }}
            />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <StreakCoachMark>
          <Popover.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={styles.widgetButton}
              onClick={trackOnClick}
            >
              <VisuallyHidden>View Streak</VisuallyHidden>
              {streak.status === 'OnTrack' && !onboardPending && (
                <OnTrackRing className={styles.onTrackRing} />
              )}
              <StreakIcon />
              {showStreakCount && (
                <div className={clsx(styles.widgetCount, statusClass)}>
                  {streak.count}
                </div>
              )}
            </Button>
          </Popover.Trigger>
        </StreakCoachMark>
        <Popover.Content align="center" arrow>
          <StreakWidgetContent
            streak={streak}
            onClose={(): void => setOpen(false)}
          />
        </Popover.Content>
      </Popover>
    </>
  )
}

export default StreakWidget
